import { merge, omit, cloneDeep } from 'lodash';
import type { UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import type {
  I$WWrapper,
  IWidgetController,
} from '@wix/native-components-infra/dist/src/types/types';

import {
  selectStateDeclarations,
  selectIsWarmupDataRestored,
} from 'store/application/selectors';
import { actions } from 'store/application';
import { STORE_WARMUP_DATA_KEY, ROUTER_WARMUP_DATA_KEY } from 'store/constants';
import type { IRootStore } from 'store/types';
import { restoreResolvables } from 'router/helpers';

import { ApplicationController } from './application.controller';
import { CentralFeedController } from './central-feed.controller';
import { CommentsController } from './comments.controller';
import { EventsController } from './events.controller';
import { FeedController } from './feed.controller';
import { FilesController } from './files.controller';
import { GroupController } from './group.controller';
import { GroupsController } from './groups.controller';
import { MediaController } from './media.controller';
import { MembersController } from './members.controller';
import { RouterController } from './router.controller';
import { TopicsController } from './topics.controller';

import { getAppData } from './helpers';
import { ESettingsEvents, IControllerVM, SettingsEventHandler } from './types';

export class WixGroupsController implements IWidgetController {
  public isMocked: boolean;
  public store: IRootStore;
  public router: UIRouter;
  public vm: IControllerVM;

  protected settingsEvents: ReturnType<typeof createEventHandler>;

  constructor(public params: ControllerParams) {
    const { router, store, isMocked } = getAppData(params);

    const { config, platformAPIs } = params.flowAPI.controllerConfig;

    this.router = router;
    this.store = store;
    this.isMocked = !!isMocked;

    const commentsController = CommentsController(this.params);

    this.vm = merge(
      commentsController,
      ApplicationController(this.params),
      CentralFeedController(this.params, commentsController),
      CommentsController(this.params),
      EventsController(this.params),
      FeedController(this.params, commentsController),
      FilesController(this.params),
      GroupController(this.params),
      GroupsController(this.params),
      MediaController(this.params),
      MembersController(this.params),
      RouterController(this.params),
      TopicsController(this.params),
    );

    store.dispatch(
      actions.setAppData({ metaSiteId: platformAPIs.bi?.metaSiteId }),
    );

    this.settingsEvents = createEventHandler<SettingsEventHandler>(
      config.publicData.COMPONENT,
    );

    this.settingsEvents.on(
      ESettingsEvents.RequestState,
      this.handleRouterStateChangeRequest.bind(this),
    );

    this.settingsEvents.onReset(this.handleSettingsReset.bind(this));

    this.setupStates();
  }

  setupStates() {
    const { vm, isMocked } = this;
    const states = selectStateDeclarations(this.store.getState());

    if (isMocked) {
      return;
    }

    this.router.stateRegistry.register({
      ...states['social-groups'],
      resolvePolicy: { when: 'EAGER' },
      resolve: [
        {
          token: 'user',
          async resolveFn() {
            const user = await vm.application$.fetchUserProfile().unwrap();

            return user;
          },
        },
      ],
    });

    for (const [key, state] of Object.entries(states)) {
      if (key !== 'social-groups') {
        this.router.stateRegistry.register(cloneDeep(state));
      }
    }
  }

  async pageReady() {
    if (!this.isMocked) {
      await this.resolve();
    }

    this.setInitialProps();

    if (!this.isMocked) {
      this.router.urlService.listen();
    }

    this.store.subscribe(this.updateStore.bind(this));
  }

  updateConfig($w: I$WWrapper, updatedConfig: { [key: string]: any }) {
    this.settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
  }

  onBeforeUnLoad() {
    this.vm._.comments.dispose();
    this.router.dispose();
  }

  protected async resolve() {
    const { flowAPI } = this.params;
    const { wixCodeApi } = flowAPI.controllerConfig;
    const { warmupData } = wixCodeApi.window;
    const { isSSR } = flowAPI.environment;

    const isRestored = selectIsWarmupDataRestored(this.store.getState());

    if (isSSR) {
      const resolvables = await this.handleTransition().catch(() => {});
      const state = this.store.getState();

      warmupData.set(ROUTER_WARMUP_DATA_KEY, resolvables);
      warmupData.set(
        STORE_WARMUP_DATA_KEY,
        merge({}, state, {
          application: {
            appData: { restored: true },
          },
        }),
      );

      return;
    }

    // Editor/Preview flow or Viewer SSR failed so CSR will refetch everything
    if (!isRestored) {
      this.handleTransition().catch(() => {});

      return;
    }

    // Regular flow for CSR after success SSR
    const cleanup = restoreResolvables(
      this.router,
      warmupData.get(ROUTER_WARMUP_DATA_KEY),
    );

    await this.handleTransition().catch(() => {});

    cleanup();
  }

  public handleTransition(): Promise<Record<string, any>> {
    return Promise.resolve({});
  }

  public handleSettingsReset() {}

  public handleRouterStateChangeRequest(state: string) {
    this.router.stateService.go(state);
  }

  protected updateStore() {
    const { setProps } = this.params.flowAPI.controllerConfig;

    setProps({
      store: this.store.getState(),
    });
  }

  public setInitialProps() {
    const { setProps } = this.params.flowAPI.controllerConfig;

    setProps(
      merge(
        {
          fitToContentHeight: true,
          store: this.store.getState(),
        },
        omit(this.vm, '_'),
      ),
    );

    this.vm._.comments.bind();
  }
}
