import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as media from 'store/media';

import { getAppData } from './helpers';

export function MediaController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    media$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(media.thunks.fetch(groupId));
  }
}

export type IMediaController = ReturnType<typeof MediaController>;
