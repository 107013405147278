import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { WixGroupsController } from 'controllers/controller';
import { waitForTransition } from 'router/helpers';

import { setupRouter } from '../router';

export const createController: CreateControllerFn = async (params) => {
  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;

  setupRouter(params, controller.vm);

  controller.router.urlService.rules.initial({ state: 'groups' });
  controller.router.urlService.rules.otherwise({ state: 'groups' });

  return controller;

  async function handleTransition() {
    controller.router.urlService.sync();

    return waitForTransition('groups', controller.router, [
      'comments',
      'commentsApi',
    ]);
  }
};
