import type { UIRouter, Transition } from '@uirouter/core';

export async function waitForTransition(
  state: string,
  router: UIRouter,
  blacklist: string[] = [],
): Promise<Record<string, any>> {
  return new Promise((resolve, reject) => {
    const errorListener = router.transitionService.onError({}, handleError);

    const successListener = router.transitionService.onFinish(
      { to: state },
      handleResolve,
    );

    function handleError() {
      reject();
      successListener();
      errorListener();
    }

    async function handleResolve(transition: Transition) {
      const resolvables = await collectResolvables(transition, blacklist);

      resolve(resolvables);
      successListener();
      errorListener();
    }
  });
}

export function restoreResolvables(
  router: UIRouter,
  resolvables: Record<string, any> = {},
) {
  return router.transitionService.onBefore(
    {},
    (transition) => {
      for (const pathNode of transition.treeChanges().to) {
        for (const resolvable of pathNode.resolvables) {
          if (!Object.hasOwn(resolvables, resolvable.token)) {
            continue;
          }

          resolvable.data = resolvables[resolvable.token];
          resolvable.resolved = true;
          resolvable.promise = Promise.resolve(resolvable.data);
        }
      }
    },
    { priority: 1001 },
  );
}

export async function collectResolvables(
  transition: Transition,
  blacklist: string[] = [],
): Promise<Record<string, any>> {
  const CORE_RESOLVABLES = ['$stateParams', '$state$', '$transition$'];

  return Promise.all(
    transition
      .getResolveTokens()
      .filter(
        (token) =>
          typeof token === 'string' &&
          ![CORE_RESOLVABLES, blacklist].flat().includes(token),
      )
      .map(async (token) => {
        const resolvable = await transition.injector().getAsync(token);

        return [token, resolvable];
      }),
  ).then(Object.fromEntries);
}
