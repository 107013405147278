import { GroupAppKey } from './types';

export const MEMBER_LABELS = {
  Members: 'groups-web.members',
  Students: 'groups-web.members.students',
  Coworkers: 'groups-web.members.coworkers',
  Friends: 'groups-web.members.friends',
  Gamers: 'groups-web.members.gamers',
  Custom: 'groups-web.members.custom',
};

export const TABS_WHITELIST = [
  GroupAppKey.MEMBERS_APP,
  GroupAppKey.FEED_APP,
  GroupAppKey.ABOUT_APP,
  GroupAppKey.GALLERY_APP,
  GroupAppKey.EVENTS_APP,
  GroupAppKey.CUSTOM_APP,
];

export const ENABLED_TABS = [GroupAppKey.FEED_APP, GroupAppKey.ABOUT_APP];
