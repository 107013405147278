import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';

import * as groupsApi from 'api/groups/groups.api';

import * as application from 'store/application';

import { QueryJoinedGroupsParams } from './types';

export const query = createAsyncThunk(
  'joined-groups:query',
  async function (params: QueryJoinedGroupsParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ViewerScriptFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        groupsApi.getJoinedGroups({
          paging: {
            offset: params.offset,
            limit: params.limit || 10,
          },
          filter: {
            lowerCaseTitle: { $contains: params.title?.toLowerCase() },
          },
        }),
      );
      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.joined-groups.query'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
