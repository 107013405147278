import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type {
  IApplicationDialog,
  IShowDialogPayload,
  IShowToastPayload,
} from 'store/application/types';

import * as application from 'store/application';

import { getAppData } from './helpers';

export function ApplicationController(params: ControllerParams) {
  const { store, router } = getAppData(params);
  const { wixCodeApi } = params.flowAPI.controllerConfig;

  wixCodeApi.user.onLogin(() => {
    router.stateService.reload('social-groups');
  });

  return {
    application$: {
      fetchUserProfile,
      shareProfileConsent,
      showToast,
      closeToast,
      showDialog,
      closeDialog,
      follow,
      unfollow,
      promptLogin,
    },
  };

  function promptLogin() {
    store.dispatch(application.thunks.login());
  }

  function shareProfileConsent(groupId: string, value: boolean) {
    store.dispatch(application.actions.shareProfileConsent({ groupId, value }));
  }

  function follow(memberId: string) {
    store.dispatch(application.thunks.follow(memberId));
  }

  function unfollow(memberId: string) {
    store.dispatch(application.thunks.unfollow(memberId));
  }

  function showToast(params: IShowToastPayload) {
    store.dispatch(application.actions.showToast(params));
  }

  function closeToast(toastId: string) {
    store.dispatch(application.actions.closeToast(toastId));
  }

  function showDialog(params: IShowDialogPayload) {
    store.dispatch(application.actions.showDialog(params));
  }

  function closeDialog(dialog: IApplicationDialog) {
    store.dispatch(application.actions.closeDialog(dialog));
  }

  function fetchUserProfile() {
    return store.dispatch(application.thunks.fetchCurrentUserProfile());
  }
}

export type IApplicationController = ReturnType<typeof ApplicationController>;
