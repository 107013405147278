import { createSlice } from '@reduxjs/toolkit';

import { joinedGroupsAdapter } from './adapter';
import * as thunks from './thunks';

import { JoinedGroupsStateExtra } from './types';

export const initialState =
  joinedGroupsAdapter.getInitialState<JoinedGroupsStateExtra>({
    error: false,
    loading: false,
    metadata: {},
  });

export const joinedGroupsSlice = createSlice({
  name: 'joinedGroups',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.query.pending, (state, action) => {
      const { offset } = action.meta.arg;

      if (offset) {
        return;
      }
      state.loading = true;
      state.error = false;
    });

    builder.addCase(thunks.query.rejected, (state, action) => {
      const { offset } = action.meta.arg;

      if (offset) {
        return;
      }
      state.loading = false;
      state.error = true;
    });

    builder.addCase(thunks.query.fulfilled, (state, action) => {
      const { metadata = {}, groups } = action.payload;
      state.loading = false;
      state.error = false;
      state.metadata = metadata;

      if (metadata.offset) {
        joinedGroupsAdapter.addMany(state, groups);
      } else {
        joinedGroupsAdapter.setAll(state, groups);
      }
    });
  },
});
