import { keyBy, omit } from 'lodash';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { groupsAdapter } from './adapter';
import * as thunks from './thunks';
import * as feedThunks from '../feed/thunks';

import { IGroupApps, IGroupsStateExtras } from './types';

export const initialState = groupsAdapter.getInitialState<IGroupsStateExtras>({
  loading: false,
  update: {},
  fetch: {},
  create: {},
  remove: {},
  membership: {},
  activity: {},
  rules: {},
  requirements: {},
  questions: {},
  notificationSettings: {},
  applications: {},
});

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    updateMembersCounter(
      state,
      action: PayloadAction<{ groupId: string; count: number }>,
    ) {
      const { groupId, count } = action.payload;

      const group = groupsAdapter.getSelectors().selectById(state, groupId);

      if (group) {
        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            membersCount: (group.membersCount as number) + count,
          },
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchRules.pending, function (state, action) {
        const groupId = action.meta.arg;

        state.rules[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchRules.rejected, function (state, action) {
        const groupId = action.meta.arg;

        state.rules[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchRules.fulfilled, function (state, action) {
        const groupId = action.meta.arg;
        const data = action.payload;

        state.rules[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            rules: data,
          },
        });
      });

    builder
      .addCase(thunks.fetchActivity.pending, function (state, action) {
        const groupId = action.meta.arg;

        state.activity[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchActivity.rejected, function (state, action) {
        const groupId = action.meta.arg;

        state.activity[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchActivity.fulfilled, function (state, action) {
        const groupId = action.meta.arg;
        const data = action.payload;

        state.activity[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            activity: {
              ...data,
              recentActivityDate: data.recentActivityDate?.getTime(),
            },
          },
        });
      });

    builder.addCase(thunks.updateGroupInfo.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.update[groupId] = {
        loading: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          ...omit(action.payload, 'slug'),
          coverImage: action.payload.coverImage || undefined,
        },
      });
    });

    builder.addCase(
      thunks.updateGroupSettings.fulfilled,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: false,
          error: false,
        };

        const group = groupsAdapter.getSelectors().selectById(state, groupId);

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            settings: {
              ...group?.settings,
              ...action.payload,
            },
          },
        });
      },
    );

    builder
      .addCase(thunks.fetchGroup.pending, (state, action) => {
        const groupId = action.meta.arg;

        state.fetch[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchGroup.rejected, (state, action) => {
        const groupId = action.meta.arg;

        state.fetch[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchGroup.fulfilled, function (state, action) {
        const groupId = action.meta.arg;
        const { apps, group } = action.payload;

        const applications = keyBy(apps, 'key') as IGroupApps;

        state.fetch[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.upsertOne(state, {
          ...group,
          applications,
        });
      });

    builder.addCase(
      feedThunks.fetchCentralFeed.fulfilled,
      function (state, action) {
        const { data } = action.payload;

        groupsAdapter.addMany(state, data.groups);
      },
    );

    builder.addCase(thunks.fetchQuestions.pending, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: true,
        error: false,
      };
    });

    builder.addCase(thunks.fetchQuestions.rejected, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: false,
        error: true,
      };
    });

    builder.addCase(thunks.fetchQuestions.fulfilled, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          questions: action.payload,
        },
      });
    });

    builder.addCase(thunks.updateQuestions.pending, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: true,
        error: false,
      };
    });

    builder.addCase(thunks.updateQuestions.rejected, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: false,
        error: true,
      };
    });

    builder.addCase(thunks.updateQuestions.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          questions: action.payload,
        },
      });
    });

    builder.addCase(
      thunks.fetchJoinRequirements.pending,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.requirements[groupId] = {
          loading: true,
          error: false,
        };
      },
    );

    builder.addCase(
      thunks.fetchJoinRequirements.rejected,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.requirements[groupId] = {
          loading: false,
          error: true,
        };
      },
    );

    builder.addCase(
      thunks.fetchJoinRequirements.fulfilled,
      function (state, action) {
        const { groupId } = action.meta.arg;
        const data = action.payload;

        state.requirements[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            requirements: data,
          },
        });
      },
    );

    builder.addCase(thunks.join.pending, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: true,
        error: false,
      };
    });

    builder.addCase(thunks.join.rejected, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: false,
        error: true,
      };
    });

    builder.addCase(thunks.join.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: action.payload,
      });
    });

    builder
      .addCase(
        thunks.fetchNotificationSettings.pending,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: true,
            error: false,
          };
        },
      )
      .addCase(
        thunks.fetchNotificationSettings.rejected,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: false,
            error: true,
          };
        },
      )
      .addCase(
        thunks.fetchNotificationSettings.fulfilled,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: false,
            error: false,
          };

          const group = groupsAdapter.getSelectors().selectById(state, groupId);

          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              notificationSettings: {
                ...(group?.notificationSettings || {}),
                [channel!]: action.payload,
              },
            },
          });
        },
      );

    builder
      .addCase(
        thunks.updateNotificationSettings.pending,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          existedNotificationSettings[channel!] = {
            updating: true,
            updateError: false,
          };
        },
      )
      .addCase(
        thunks.updateNotificationSettings.rejected,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          state.notificationSettings[groupId] = {
            ...existedNotificationSettings,
            [channel!]: {
              updating: false,
              updateError: true,
            },
          };
        },
      )
      .addCase(
        thunks.updateNotificationSettings.fulfilled,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          state.notificationSettings[groupId] = {
            ...existedNotificationSettings,
            [channel!]: {
              updating: false,
              updateError: false,
            },
          };

          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              notificationSettings: {
                [channel!]: action.payload,
              },
            },
          });
        },
      );

    builder
      .addCase(thunks.updateGroupApps.pending, (state, action) => {
        const { groupId } = action.meta.arg;
        state.applications[groupId] = {
          updating: true,
          error: false,
        };
      })
      .addCase(thunks.updateGroupApps.rejected, function (state, action) {
        const { groupId } = action.meta.arg;

        state.applications[groupId] = {
          updating: false,
          error: true,
        };
      })
      .addCase(thunks.updateGroupApps.fulfilled, function (state, action) {
        const { groupId, apps } = action.meta.arg;
        state.applications[groupId] = {
          updating: false,
          error: false,
        };
        const appsAsMap = apps.reduce(function (res, app) {
          if (app && app.key) {
            res[app.key] = app;
          }
          return res;
        }, {} as IGroupApps);

        const group = groupsAdapter.getSelectors().selectById(state, groupId);

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            applications: {
              ...(group?.applications! || {}),
              ...appsAsMap,
            },
          },
        });
      });

    builder.addMatcher(
      isAnyOf(thunks.leave.pending, thunks.cancelRequest.pending),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: true,
          error: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(thunks.leave.rejected, thunks.cancelRequest.rejected),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: false,
          error: true,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(thunks.leave.fulfilled, thunks.cancelRequest.fulfilled),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: action.payload,
        });
      },
    );

    builder.addMatcher(
      isAnyOf(
        thunks.updateGroupInfo.pending,
        thunks.updateGroupSettings.pending,
      ),
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: true,
          error: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(
        thunks.updateGroupInfo.rejected,
        thunks.updateGroupSettings.rejected,
      ),
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: false,
          error: true,
        };
      },
    );
  },
});
