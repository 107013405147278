import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as files from 'store/files';

import { getAppData } from './helpers';

export function FilesController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    files$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(files.thunks.fetch(groupId));
  }
}

export type IFilesController = ReturnType<typeof FilesController>;
