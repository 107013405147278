import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as events from 'store/events';

import { getAppData } from './helpers';

export function EventsController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    events$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(events.thunks.fetch(groupId));
  }
}

export type IEventsController = ReturnType<typeof EventsController>;
