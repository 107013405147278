import type { RawParams, Transition, TransitionOptions } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as application from 'store/application';

import { getAppData } from './helpers';

export function RouterController(params: ControllerParams) {
  const { store, router } = getAppData(params);

  router.transitionService.onBefore({}, handleRouterUpdate);
  router.transitionService.onError({}, handleRouterUpdate);

  return {
    router$: {
      go(state: string, params?: RawParams, options?: TransitionOptions) {
        router.stateService.go(state, params, options);
      },
      reload(state?: string) {
        router.stateService.reload(state);
      },
    },
  };

  async function handleRouterUpdate(transition: Transition) {
    store.dispatch(
      application.actions.setRouterCurrent({
        url: router.urlService.url(),
        state: transition.to().name as string,
        params: transition.params('to'),
      }),
    );
  }
}

export type IRouterController = ReturnType<typeof RouterController>;
