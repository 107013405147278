import { ControllerParams } from '@wix/yoshi-flow-editor';

import * as joinedGroups from 'store/joined-groups';

import { getAppData } from './helpers';

export function GroupsController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    groups$: {
      queryJoinedGroups,
    },
  };

  function queryJoinedGroups(
    title?: string,
    offset: number = 0,
    limit?: number,
  ) {
    return store.dispatch(
      joinedGroups.thunks.query({
        limit,
        offset,
        title,
      }),
    );
  }
}

export type IGroupsController = ReturnType<typeof GroupsController>;
