import {
  ControllerParams,
  CreateControllerFn,
  IControllerConfig,
} from '@wix/yoshi-flow-editor';
import { GroupsPageController } from './controllers/GroupsPageController';
import { createController } from './controllers$/controller';

const _createController: CreateControllerFn = async (
  controllerContext: ControllerParams,
) => {
  console.info('YO migration groups createController');
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  const legacy = new GroupsPageController(controllerContext);
  const controller = await createController(controllerContext);

  return {
    async pageReady($w, wixAPI) {
      await Promise.all([
        legacy.pageReady($w, wixAPI),
        controller.pageReady($w, wixAPI),
      ]);
    },

    updateConfig($w, updatedConfig) {
      legacy.updateConfig($w, updatedConfig as IControllerConfig);
      controller.updateConfig?.($w, updatedConfig as IControllerConfig);
    },

    updateAppSettings($w, updates) {
      controller.updateAppSettings?.($w, updates);
    },

    onBeforeUnLoad() {
      legacy.onBeforeUnLoad();
      controller.onBeforeUnLoad?.();
    },

    exports() {
      return {
        ...legacy.exports(),
        ...controller.exports?.(),
      };
    },
  };
};

export default _createController;
