import { ISettingsParam } from '@wix/tpa-settings';

export type IFeedSettingsParams = {
  showCreatePost: ISettingsParam<boolean>;
  showFeedTitle: ISettingsParam<boolean>;
  feedTitle: ISettingsParam<string | null>;
};

export const feedSettingsParams: IFeedSettingsParams = {
  feedTitle: {
    getDefaultValue: () => null,
    key: 'feedTitle',
  },
  showCreatePost: {
    getDefaultValue: () => true,
    key: 'showCreatePost',
  },
  showFeedTitle: {
    getDefaultValue: () => true,
    key: 'showFeedTitle',
  },
};
