import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as topics from 'store/topics';

import { getAppData } from './helpers';

export function TopicsController(params: ControllerParams) {
  const { store } = getAppData(params);

  return {
    topics$: {
      create,
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(topics.thunks.fetch(groupId));
  }

  function create(groupId: string, label: string) {
    return store.dispatch(
      topics.thunks.create({
        groupId,
        label,
      }),
    );
  }
}

export type ITopicsController = ReturnType<typeof TopicsController>;
