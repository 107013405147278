import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RawParams, StateDeclaration } from '@uirouter/core';
import uuid from 'uuid/v4';
import { keyBy } from 'lodash';

import { toastsAdapter } from './toasts';
import * as thunks from './thunks';
import type {
  IApplicationDialog,
  IApplicationState,
  IApplicationUser,
  IShowDialogPayload,
  IShowToastPayload,
} from './types';

export const initialState: IApplicationState = {
  user: {
    following: [] as string[],
  } as IApplicationUser,
  appData: {
    // has been restored via warmupData
    restored: false,
    metaSiteId: '',
  },
  shareProfileConsent: {},
  toasts: toastsAdapter.getInitialState(),
  dialogs: {
    createPost: {},
    joinGroup: {},
    disclaimer: {},
    groupQuestions: {},
    eventsRestriction: {},
    paidPlansRestriction: {},
    futurePlanDialog: {},
    cancelJoinRequest: {},
    leaveGroup: {},
  },
  status: {
    follow: {},
  },
  router: {
    url: '',
    baseUrl: '',
    state: '',
    params: {},
    states: {},
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setAppData(state, action: PayloadAction<Record<string, any>>) {
      state.appData = {
        ...state.appData,
        ...action.payload,
      };
    },

    shareProfileConsent(
      state,
      action: PayloadAction<{ groupId: string; value: boolean }>,
    ) {
      const { groupId, value } = action.payload;

      state.shareProfileConsent[groupId] = value;
    },

    showToast(state, action: PayloadAction<IShowToastPayload>) {
      toastsAdapter.setOne(state.toasts, {
        ...action.payload,
        id: uuid(),
      });
    },

    closeToast(state, action: PayloadAction<string>) {
      toastsAdapter.removeOne(state.toasts, action.payload);
    },

    showDialog(state, action: PayloadAction<IShowDialogPayload>) {
      const { dialog, groupId, params } = action.payload;

      Object.keys(state.dialogs).forEach((key) => {
        const name = key as IApplicationDialog;
        const dialog = state.dialogs[name];

        state.dialogs[name] = {
          ...dialog,
          isOpen: false,
        };
      });

      state.dialogs[dialog] = {
        isOpen: true,
        groupId,
        params,
      };
    },

    closeDialog: (state, action: PayloadAction<IApplicationDialog>) => {
      const dialog = action.payload;

      state.dialogs[dialog] = {
        ...state.dialogs[dialog],
        isOpen: false,
      };
    },

    closeAllDialogs(state) {
      Object.keys(state.dialogs).forEach((key) => {
        const name = key as IApplicationDialog;
        const dialog = state.dialogs[name];

        state.dialogs[name] = {
          ...dialog,
          isOpen: false,
        };
      });
    },

    setupRouter(
      state,
      action: PayloadAction<{
        baseUrl: string;
        states: StateDeclaration[];
      }>,
    ) {
      const { baseUrl, states } = action.payload;

      state.router.baseUrl = baseUrl;
      state.router.url = baseUrl;
      state.router.states = keyBy(states, 'name');
    },

    setRouterCurrent(
      state,
      action: PayloadAction<{ url: string; params: RawParams; state: string }>,
    ) {
      const { payload } = action;
      state.router.url = state.router.baseUrl + payload.url;
      state.router.state = payload.state;
      state.router.params = payload.params;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunks.follow.pending, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.follow.rejected, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.follow.fulfilled, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: false,
        };

        state.user.following.push(memberId);
      });

    builder
      .addCase(thunks.unfollow.pending, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.unfollow.rejected, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.unfollow.fulfilled, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: false,
        };

        state.user.following = state.user.following.filter(
          (id) => id !== memberId,
        );
      });

    builder.addCase(
      thunks.fetchCurrentUserProfile.fulfilled,
      (state, action) => {
        state.user = action.payload as IApplicationUser;
      },
    );
  },
});
